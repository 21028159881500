/**
 * Object
 * COLOR SWATCH
 */

.color-swatch {
  display: block;
  padding: 20px 10px;

  position: relative;

  text-align: center;

  border-radius: 3px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;

    box-shadow: 0 0 0 1px rgba(0,0,0,.1) inset;
    border-radius: 3px;
  }
}
