/**
 * Object
 * LABEL
 */

.label-admin {
  display: inline-block;
  width: 31px;
  height: 31px;
  padding: 0;

  color: $black;
  font-size: 0.5rem;
  line-height: 31px;
  text-align: center;
  vertical-align: middle;

  border-radius: 31px;
  border: 1px solid $black;

  @media only screen and (max-width: 991px) {
    width: 27px;
    height: 27px;

    line-height: 27px;
  }
}
