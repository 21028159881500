/**
 * Object
 * COMPONENT
 */

.component {
  + .component {
    margin-top: 40px;
  }
}

.component-heading {
  margin-top: 0;

  @media screen and (min-width: 480px) {
    float: left;
  }
}

.component-anchor {
  &,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: inherit;
    text-decoration: none;
  }

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.component-label {
  position: relative;
  top: -2px;

  @media screen and (max-width: 480px) {
    float: left;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 480px) {
    margin-left: 12px;
  }
}

.component-nav {
  margin: 0;

  li {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    float: left;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 480px) {
    float: right;
    margin-right: -5px;
  }
}

.component-release-notes {
  &.collapse.in,
  &.collapsing {
    margin-left: 0;
  }

  p + ul {
    margin-top: 0;

    + p {
      margin-top: 10px;
    }
  }
}

.component-example {
  padding: 50px 4% 40px 4%;
  border: 1px solid $softBlue;
  position: relative;
  width: 100%;
  overflow-y: visible;
  box-sizing: border-box;

  &:before {
    content: 'Example';
    position: absolute;
    top: 0;
    left: 0;
    color: $empress;
    border-right: 1px solid $softBlue;
    border-bottom: 1px solid $softBlue;
    border-bottom-right-radius: 4px;
    padding: 5px 8px;
    display: inline-block;
    font-family: $mainFont;
  }

  + .code-block {
    margin-top: 0;
  }
}

.show-code {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 50;
  border: none;
  color: $empress;
  background: transparent;
  font-family: $mainFont;

  &:hover,
  &:focus {
    color: #DC0018;
  }
}


/**
 * SPECIFIC COMPONENTS
 */

// Page background component example require specific styling
// to make it works in the context of the styleguide
[id="02-general-03-background"] {
  .component-example {
    background: url('/img/bg-striped.png') repeat;
  }
}
